
.content {
    padding: 100px;
    background-color: #f5f5f5;
    min-height: 100vh;
    overflow: auto;
    @media screen and (max-width: 768px) {
        padding: 100px 50px;
    }
      
    @media screen and (max-width: 576px) {
        padding: 100px 25px;
    }
}
.img-fluid {
    max-width: 100%;
    width: auto;
    max-height: 300px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.26);
    cursor: pointer;
  }
  .row {
      &.getStarted{
        margin: 50px 0;
        align-items: center;
        flex-direction: column;
        p{
            text-align: justify;
            margin: auto;
            font-size: 18px;
            margin-bottom: 20px;
        }
      }

  }
  .custom-switch .custom-control-label::before{
    background-color: #f5f5f5;
    left: -2.25rem;
    width: 3rem;
    height: 1.25rem;
    pointer-events: all;
    border-radius: 25px;
  }
  .custom-switch .custom-control-label::after{
    background-color: #bdbdbd;
    top: 0;
    left: calc(-2.5rem + 2px);
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    border-radius: 50%;
    box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.5), 0 0 1px 1px  rgba(0, 0, 0, 0.5);


  }

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: rgba(0, 255, 106, 0.685);
    box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.5), 0 0 1px 1px rgba(0, 0, 0, 0.5);
    border: none;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after{
    transform: translateX(2rem);
}

.customerItem:hover{
  background-color: #e9e9e9;
}


//Modal

.modalBg{
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  &.active{
    z-index: 5000;
    opacity: 1;
    visibility: visible;
  }
  .closeButton{
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    background: transparent;
    border: none;
    transition: all 0.3s ease-in-out;
    &:hover{
      color: orangered;
      font-size: 25px;

    }
  }
  .p-carousel-item {
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 700px;
      object-fit: contain;
    }
   }

  .modalBody{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
  }

  
}
.cursor-pointer{
  cursor: pointer;
}

.table {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  .table-header {
    width: 100%;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: rgba(255, 255, 255, 0.9);
    
  }
  
  .table-row{
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0.5em;
    transition: all 0.1s ease-in-out;
    &.selected{
      background-color: rgba(0, 0, 0, 0.7);
      color: rgba(255, 255, 255, 0.9);
      font-weight: 500;
    }
    &:nth-child(even){
      background-color: rgba(0, 0, 0, 0.05);
    }
    &:last-child{
  /*     border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
      border-bottom: none;      
    }
  }
  .table-body{
    width: 100%;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    .table-row{
      &:hover{
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
      &.selected{
        background-color: rgba(0, 0, 0, 0.3);
        color: rgba(255, 255, 255, 0.9);
        font-weight: 500;
      }
    }
  }
  .table-cell{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.2em;
    &.title{
      max-width: 150px;
    }
  }
}

.p-card .p-card-content{
  padding: 0.5rem 0 !important;
}
.p-card .p-card-body{
  padding-top:0 !important;
}
  
button:focus {
  outline: 2px solid black !important;
}
.focus-visible:focus {
  outline: 2px solid black !important;
}
.p-dialog .p-dialog-header{
  align-items: start !important;
}
.p-element.p-ripple.p-autocomplete-dropdown.p-button.p-component.p-button-icon-only {
  // color: red;
  padding: 0.25rem 0.75rem;
  width:auto;
}


.p-inputtext{
  padding: 0.35rem 0.75rem !important;
}
.bg-none{
  background-color: transparent !important;
}
.border-none{
  border: none !important;
}
.p-button{
  padding: 0.35rem 0.75rem !important;
}