/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';



$primaryColor : #007bff;
$secondaryColor : #6c757d;

.hallo {
  color: $primaryColor;
}

.custom-switches {
  .switch {
    background: #fb6542;

    small {
      background: #375e97;
    }

    &.checked {
      background: #3f681c;
    }
  }
}
body {
    width: 100vw;
    height: 100vh;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.user-menu > .nav-link {
    cursor: pointer;
}

.user-menu > .nav-link:after {
    content: none;
}

.user-menu > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
    border-bottom: 1px solid #495057;
    border-top: 1px solid #dee2e6;
    padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
    display: block;
    clear: both;
    content: '';
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-body a {
        background: #ffffff !important;
        color: #495057 !important;
    }
}

.user-menu > .dropdown-menu > .user-footer {
    background-color: #f8f9fa;
    padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
    display: block;
    clear: both;
    content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #6c757d;
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f8f9fa;
    }
}

.user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2.1rem;
    margin-right: 10px;
    margin-top: -2px;
    width: 2.1rem;
}

@media (min-width: 576px) {
    .user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-right: 0.4rem;
        margin-top: -8px;
    }
}

.user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.content {
    padding: 0 0.5rem;
}

/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.form-control:disabled, .form-control:read-only {
  background-color: transparent;
}

.btn-user {
  background-color: transparent !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
}

.side-form {
  position: absolute;
  z-index: 100;
  /*top: 25px;*/
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  /*padding: 20px;*/
  border-left: 1px solid #e0e0e0;
  padding-bottom: 50px;
  padding-top: 50px;
}


.side-form-detail {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow: auto;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid #e0e0e0;
  padding-bottom: 80px;
}

table {
  width: 100%;
}

.mat-table {
  background: white;
  color: black;
}

.mat-header-cell {
  color: black;
}

.mat-cell, .mat-footer-cell {
  color: black;
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: black;
}

.mat-paginator {
  background: white;
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: grey;
}

td.mat-cell {
  /* row padding */
  padding: 16px 0;
  /* row border */
  border-bottom: 1px solid grey;
}

th.mat-header-cell {
  /* row padding */
  padding: 16px 0;
  /* row border */
  border-bottom: 1px solid grey;
}

.mat-select-value {
  color: black;
}

.mat-value {
  color: black;
}

.mat-select-arrow {
  color: black;
}

.mat-option-text {
  color: black;
}

.mat-select-panel {
  background: white;
}

.mat-form-field-label {
  color: black;
}

.mat-form-field-infix {
  border-bottom: 1px solid grey;
}

table {
  /* row spacing / margin */
  border-spacing: 0 8px !important;
  border-collapse: unset;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: none;
  border: 1px solid grey;
}

.mat-checkbox-unchecked.mat-accent {
  background-color: none;
  border: 1px solid grey;
}

.mat-checkbox-inner-container {
  background-color: none;
  border: 1px solid grey;
}

.selectedRow {
  background-color: lightgrey;
}

.unselectedRow {
  background-color: white;
}

.error:focus,
.error {
  border-color: #E91E63;
  color: #E91E63;
}

.invalid-feedback {
  color: #E91E63;
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
}

.brand-link .brand-image {
  margin-top: 2px !important;
  margin-right: 0rem;
  margin-left:0rem;

}

/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.form-control:disabled, .form-control:read-only {
  background-color: transparent;
}

.btn-user {
  background-color: transparent !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
}

.side-form {
  position: absolute;
  z-index: 100;
  /*top: 25px;*/
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  /*padding: 20px;*/
  border-left: 1px solid #e0e0e0;
  padding-bottom: 50px;
  padding-top: 50px;
}


.side-form-detail {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow: auto;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid #e0e0e0;
  padding-bottom: 80px;
}

table {
  width: 100%;
}

.mat-table {
  background: white;
  color: black;
}

.mat-header-cell {
  color: black;
}

.mat-cell, .mat-footer-cell {
  color: black;
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: black;
}

.mat-paginator {
  background: white;
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: grey;
}

td.mat-cell {
  /* row padding */
  padding: 16px 0;
  /* row border */
  border-bottom: 1px solid grey;
}

th.mat-header-cell {
  /* row padding */
  padding: 16px 0;
  /* row border */
  border-bottom: 1px solid grey;
}

.mat-select-value {
  color: black;
}

.mat-value {
  color: black;
}

.mat-select-arrow {
  color: black;
}

.mat-option-text {
  color: black;
}

.mat-select-panel {
  background: white;
}

.mat-form-field-label {
  color: black;
}

.mat-form-field-infix {
  border-bottom: 1px solid grey;
}

table {
  /* row spacing / margin */
  border-spacing: 0 8px !important;
  border-collapse: unset;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: none;
  border: 1px solid grey;
}

.mat-checkbox-unchecked.mat-accent {
  background-color: none;
  border: 1px solid grey;
}

.mat-checkbox-inner-container {
  background-color: none;
  border: 1px solid grey;
}

.selectedRow {
  background-color: lightgrey;
}

.unselectedRow {
  background-color: white;
}

.error:focus,
.error {
  border-color: #E91E63;
  color: #E91E63;
}

.invalid-feedback {
  color: #E91E63;
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
}

.fixed-navbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.layout-fixed .main-sidebar {
  bottom: 0;
  float: none;
  left: 0;
  position: fixed !important;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1032;
}

// .wrapper .content-wrapper {
//   min-height: calc(100vh - calc(3.5rem + 1px) - calc(0em + 0px)) !important;
// }
/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.form-control:disabled, .form-control:read-only {
  background-color: transparent;
}

.btn-user {
  background-color: transparent !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
}

.side-form {
  position: absolute;
  z-index: 100;
  /*top: 25px;*/
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  /*padding: 20px;*/
  border-left: 1px solid #e0e0e0;
  padding-bottom: 50px;
  padding-top: 50px;
}


.side-form-detail {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow: auto;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid #e0e0e0;
  padding-bottom: 80px;
}

table {
  width: 100%;
}

.mat-table {
  background: white;
  color: black;
}

.mat-header-cell {
  color: black;
}

.mat-cell, .mat-footer-cell {
  color: black;
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  color: black;
}

.mat-paginator {
  background: white;
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: grey;
}

td.mat-cell {
  /* row padding */
  padding: 16px 0;
  /* row border */
  border-bottom: 1px solid grey;
}

th.mat-header-cell {
  /* row padding */
  padding: 16px 0;
  /* row border */
  border-bottom: 1px solid grey;
}

.mat-select-value {
  color: black;
}

.mat-value {
  color: black;
}

.mat-select-arrow {
  color: black;
}

.mat-option-text {
  color: black;
}

.mat-select-panel {
  background: white;
}

.mat-form-field-label {
  color: black;
}

.mat-form-field-infix {
  border-bottom: 1px solid grey;
}

table {
  /* row spacing / margin */
  border-spacing: 0 8px !important;
  border-collapse: unset;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: none;
  border: 1px solid grey;
}

.mat-checkbox-unchecked.mat-accent {
  background-color: none;
  border: 1px solid grey;
}

.mat-checkbox-inner-container {
  background-color: none;
  border: 1px solid grey;
}

.selectedRow {
  background-color: lightgrey;
}

.unselectedRow {
  background-color: white;
}

.error:focus,
.error {
  border-color: #E91E63;
  color: #E91E63;
}

.invalid-feedback {
  color: #E91E63;
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
}

.dropdown-menu{
    background-color: transparent !important;
}

.pageTitle {
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0;  
  .fa-3x {
    font-size: 1em;
    margin-right: 12px
  }
}
h2.pageTitle{
  font-size: 28px;
  font-weight: 600;
}

@import 'custom.scss';